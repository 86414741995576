<template>
  <div class="login">
    <div>
      <img
        class="logo-name"
        src="../../assets/images/sign-in-logo.png"
        alt="logo"
        @click="handleToHome"
      />
    </div>
    <div class="login-wrap">
      <h1 class="title">Login</h1>
      <el-form :model="form" ref="form" class="demo-ruleForm">
        <el-form-item prop="username">
          <el-input
            placeholder="Username"
            type="username"
            v-model="form.username"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item id="Password" prop="pass">
          <el-input
            placeholder="Password"
            type="password"
            v-model="form.password"
            autocomplete="off"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <el-form-item class="login-btn">
          <el-button type="primary" @click="login"> Login </el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="register-text">
        <p @click="handleCommand">注册帐号</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      is_login: true,
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      const self = this;
      let status_code = -1;
      let email = "";
      let password = "";
      if (
        self.form.username == "consultant" &&
        self.form.password == "oxvalue888"
      ) {
        email = "info@oxvalue.ai"; //已添加的测试账户
        password = "oxvalueadmin888";
      } else {
        email = this.form.username;
        password = this.form.password;
      }
      let post_data = {
        email: email,
        password: password,
      };
      this.$axios({
        method: "post",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(post_data),
        url: "https://api.oxvalue.cn/api/auth/v0.1/admin/",
      }).then((result) => {
        if (result.data.code == 200) {
          // 将响应中的token存储到本地
          status_code = 1;
          window.localStorage.setItem(
            "ova_admin_token",
            result.data.data.token
          );
          window.localStorage.setItem("ova_admin_id", result.data.data.id);
        } else {
          status_code = 4;
        }
        switch (status_code) {
          case 1:
            this.$message.success("Successfully Login!");
            this.is_login = true;
            this.$store.dispatch("saveUserInfo", {
              user: {
                username: this.form.username,
                confirmed: true,
              },
            });
            this.$router.push("/index");
            break;
          case 4:
            this.$message.error("Wrong username or password！");
            break;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleCommand() {
      this.$router.push("/register");
    },
    handleToHome() {
      this.$router.push("/index");
    },
  },
};
</script>

<style scoped>
.logo-name {
  margin-top: 30px;
  width: 350px;
  height: 175px;
  cursor: pointer;
  overflow: hidden;
}
.login {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/sign-in-5.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  position: fixed;
}
.login-wrap {
  width: 350px;
  height: 330px;
  padding: 20px 25px 0 25px;
  line-height: 40px;
  position: relative;
  display: inline-block;
  background-color: rgb(255, 255, 255, 0.85);
  border-radius: 20px;
}
h3 {
  color: #0babeab8;
  font-size: 24px;
}
hr {
  background-color: #444;
  margin: 20px auto;
}
a {
  text-decoration: none;
  color: #aaa;
  font-size: 15px;
}
a:hover {
  color: coral;
}
.login-btn {
  margin-top: 25px;
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 38px;
}
.register-text {
  font-size: 14px;
  line-height: 10px;
  color: #999;
  cursor: pointer;
  float: right;
}
.title {
  text-align: center;
  margin-bottom: 25px;
}
</style>
